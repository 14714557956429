<template>
  <v-container v-show="showForm">
    <the-page-title
      title="PROVJERITE I POTVRDITE UNESENE PODATKE"
      :steps="steps"
    />
    <the-child-info
      :name="child_name"
      :surname="child_surname"
      :pin="child_pin"
      :email="email"
    />

    <review-component />

    <div class="mb-10 mt-10">
      <v-row class="justify-center">
        <div class="pa-6 pt-4 pb-4 elevation-6 rounded fluid nbr-mw800">
            <v-alert
              class="ma-0 mt-4"
              border="top"
              color="yellow lighten-2"
            >
              <b>NAPOMENA:</b><br>
              Ukoliko se utvrdi da podatci i predana dokmentacija nisu točni Ustanova zadržava pravo promjene izabranog objekta/programa ili poništavanja zahtjeva za prijavu djeteta u vrtić!
            </v-alert>
          <v-checkbox
            :dense="denseField"
            v-model="form.confirm.value"
            :error-messages="form.confirm.error"
            @change="form.confirm.error=''"
            label="Potvrđujem točnost unesenih podataka i dostavljene dokumentacije"
          />
        </div>
      </v-row>
    </div>

    <v-row class="justify-center">
      <v-card class="pa-2 elevation-6 blue lighten-3 lighten-4 nbr-mw800 red">
        <v-card-actions>
          <v-btn
            @click="$router.push('/prijava/' + form.hash.value + '/dokumenti');"
          >Nazad</v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="warning"
            @click="postForm(null)"
          >Potvrdi zahtjev za upis</v-btn>
        </v-card-actions>
      </v-card>
    </v-row>
 </v-container>
</template>

<script>
import ReviewComponent from '../components/ReviewComponent';
import ThePageTitle from '../components/ThePageTitle';
import TheChildInfo from '../components/TheChildInfo';
import axios from 'axios';
import common from '../common.js';

export default {
  name: 'Review',
  mixins: [ common ],
  created () {
    this.getFormData();
  },

  computed: {
    denseField () {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return true;
        default: return false;
      }
    }
  },

  methods: {
    getFormData() {
      this.showOverlay();
      axios.post('', { action: 'getFormReview', hash: this.form.hash.value })
        .then((res) => {
          if (this.cmnAxiosPost(res)) {
            this.child_name = res.data.form_basic.child_name;
            this.child_surname = res.data.form_basic.child_surname;
            this.child_pin = res.data.form_basic.child_pin;
            this.email = res.data.form_basic.email;
            this.steps = res.data.steps;
          }
        })
        .catch((error) => { this.cmnAxiosError(error, false); })
        .then(() => {
          this.hideOverlay();
          this.showForm = true;
          this.init = false;
        });
    },
    postForm(route) {
      this.showOverlay();
      axios.post('', { action: 'commitSignUp', data: this.formData() })
        .then((res) => {
          if (this.cmnAxiosPost(res)) {
            if (route) {
              console.log('Route change to: ' + 'prijava/' + this.form.hash.value + '/' + route);
              this.$router.push('/prijava/' + this.form.hash.value + '/' + route);
            }
            else {
              this.showInfo({ title: 'OBAVIJEST', text: res.data.message, button: true });
            }
          }
        })
        .catch((error) => { this.cmnAxiosError(error, true); })
        .then(() => { this.hideOverlay(); });
    }
  },

  data() {
    return {
      form: {
        hash: { value: this.$route.params.hash },
        confirm: { value: false, error: '' }
      },
      showForm: false,
      steps: null,
      child_name: null,
      child_surname: null,
      child_pin: null,
      email: null
    }
  },

  components: {
    ReviewComponent,
    TheChildInfo,
    ThePageTitle
  }
};

</script>
